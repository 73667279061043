
// Data Structure:

// {
// 		title: "Opening",
// 		start: "",
// 		end: "",
// 		break: true, 		// <- true | false
// 		vimeo: "",  // <- "vimeoID"
// 		youtube: "", // <- "youtubeID"
// 		slides: "filename.pdf", // <- "Filename Slides"
// 		slides: [ // <- Multiple slides
// 			{
// 				name: "Title",
// 				file: "Filename.pdf"
// 			},
// 			...
//      ],
// 		chair: {
// 			name: "Andre Bourdoux",
// 			affiliation: "IMEC, Belgium"
// 		},
// 		papers: [
// 			{
// 				"title": "The Paper Title",
// 				"authors": "A. Bourdoux, T. Ußmüller",
// 				"ptype": "virtual"
// 			},
// 			{
// 				"title": "Yet Another Paper Title",
// 				"authors": "J. Doe, J. Smith",
// 				"ptype": "physical"
// 			},
// 		],
// 		speaker: "Fabian Diehm",
// 		affiliation: "TU Dresden, Dresden, Germany",
// 		ptype: "virtual", 	// <- "physical" | "virtual"
// 		misc: "", 			// <- any html possible
// },


export const programdata = [
	{
		title: "Day 1",
		date: "2025-01-28",
		//slidefolder: "/slides/2023/AE091EGS",
		items: [
			{
				title: "Registration and welcome coffee",
				start: "8:30",
				end: "9:00",
				break: true,
			},
			{
				title: "Tutorial 1: Integrated Sensing and Imaging: Fundamentals and Methods",
				start: "9:00",
				end: "10:15",
				speaker: "Dario Tagliaferri (Politecnico di Milano, Italy), Marouan Mizmizi (Politecnico di Milano, Italy)",
			//	chair: {
			//		name: "... ",
			//		affiliation: "IMEC, Belgium"
			//	},
			//	slides: "JC&S_Tutorial 1.pdf",
				vimeo: "1062709061",
				misc: `
					<div style="margin-top: 0.5em; font-size: 0.9em; font-style: italic;">
						Abstract: Integrated Sensing and Communication (ISAC) systems are recognized as one of the pillars of the future generation of wireless systems, representing a paradigm shift that entails two distinct functionalities into a single device, with fully shared hardware and radio resources. Within the ISAC community, “sensing” is often used as a synonym for “localization”, whereby the goal is to infer meaningful parameters characterizing the state of a set of targets of interest, e.g., position, velocity and possibly orientation. Recently, efforts on ISAC research started focusing on radio imaging, namely the generation of 2D or 3D maps of the reflectivity of the environment at the frequency of operation, from which to infer the number and the shape of targets through detection and image processing/interpretation. Conceptually, radio imaging is the main pre-requisite for localization, that follows detection on the generated image. This tutorial aims at covering the main aspects of Integrated Communication and Imaging (ICAI) systems. We commence by developing the fundamentals, namely outlining and discussing the diffraction tomography theory and the related principles, that rule the resolution of any radio imaging system. Then, we discuss the difference between ISAC and ICAI systems, in terms of both the communication-sensing trade-off and the analogies between diffraction tomography theory and estimation theory, attempting to bridge the gap with the existing ISAC literature. Lastly, we delve into ICAI methods, outlining practical synchronization techniques to enable networked ICAI systems over wireless networks.
					</div>`
			},
			{
				title: "30min Coffee Break",
				start: "10:15",
				end: "10:45",
				break: true,
			},
			{
				title: "Workshop 1: Workshop on Multi-Disciplinary Approaches to Joint Communication and Sensing in 6G",
				start: "10:45",
				end: "13:00",
				speaker: "Marja Matinmikko-Blue (University of Oulu), Arthur S. de Sena (University of Oulu), Junhyeong Kim (ETRI), Miguel Bordallo-Lopez (University of Oulu), Julia Equi (Ericsson)",
			//	chair: {
			//		name: "Marco Di Renzo",
			//		affiliation: "Paris-Saclay University, France"
			//	},
			//	slides: "Tutorial 2.pdf",
				vimeo: "1062711012",
				misc: `<div style="margin-top: 0.5em; font-size: 0.9em; font-style: italic;">
					Abstract: This workshop brings together experts to discuss the latest advances and research challenges in joint communication and sensing in a multi-disciplinary manner from technical, business and regulatory perspectives, representing EU SNS 6G-MUSICAL, Business Finland 6GBridge - WISEC, and EU CONVERGE research projects. The workshop hosts invited presentations from the projects, followed by a panel discussion to identify key research challenges from a multi-disciplinary perspective.
				</div>`
			},
			{
				title: "Lunch Break",
				start: "13:00",
				end: "14:00",
				break: true,
			},
			{
				title: "Workshop 2: Joint Communication and Sensing (JC&S) for Future Interactive, Immersive, and Intelligent (I3) Connectivity Beyond Communications",
				start: "14:00",
				end: "16:15",
				speaker: "Padmanava Sen (Barkhausen Institut), Angeliki Alexiou (University of Piraeus), Nhan Ngyuen (University of Oulu), Simon Schütze (Fraunhofer Heinrich Hertz Institute (HHI)",
			//	chair: {
			//		name: "Prof. Visa Koivunen",
			//		affiliation: "Aalto University, Finland"
			//	},
			//	slides: "Tutorial 3.pdf",
				vimeo: "1062713399",
				misc: `<div style="margin-top: 0.5em; font-size: 0.9em; font-style: italic;">
					Abstract: This workshop will focus on the different building blocks towards the I3 connectivity beyond communications. The talks will be aligned with the goals of the EU project INSTINCT (http://instinct-6g.eu/) but will not be restricted to the project. There are four sessions with the first presentation (session 1) highlighting the overarching goals of incorporating JC&S in future wireless networks that are envisioned to incorporate several functionalities, enabling previously unattainable data rates, tunability and perceptive intelligence. That will be followed by three topical deep dives (sessions 2 - 4) on near-field wavefront engineering, massive multiple-input multiple-output (MIMO) and wideband hardware/software enablement respectively for JC&S, summarized below. 
				</div>`
			},
			{
				title: "30min Coffee Break",
				start: "16:15",
				end: "16:45",
				break: true,
			},
			{
				title: "Tutorial 2: Security and Privacy Aspects of Joint Communication & Sensing (JC&S)",
				start: "16:45",
				end: "18:00",
				speaker: "Stefan Köpsell (Barkhausen Institut), Prajnamaya Dass (Barkhausen Institut)",
			//	chair: {
			//		name: "Prof. Mikko Valkama",
			//		affiliation: "Tampere University, Finland"
			//	},
			//	slides: "Tutorial 4.pdf",
				vimeo: "1062715362",
				misc: `<div style="margin-top: 0.5em; font-size: 0.9em; font-style: italic;">
					Abstract: Joint Communication and Sensing (JC&S) is a key technology envisioned to play a major role in the  future of 6G networks, where communication and environmental sensing are integrated into a unified framework. While JC&S offers numerous exciting applications across fields like industrial sectors, smart cities, and healthcare applications, it also introduces significant security and privacy challenges. Sensing systems often collect sensitive information, such as personally identifiable information (PII) and confidential data from both human and non-human targets, which can be vulnerable to attacks or misuse. This course aims to provide a foundational understanding of these security and privacy risks in JC&S, discuss the technical, organisational, legal and regulatory requirements for addressing them, and explore relevant policies and guidelines necessary to ensure the safe and secure deployment of this transformative technology.
				</div>`
			},
			{
				title: "Warm Drink in front of Hotel Lasaretti",
				start: "18:15",
				break: true,
			},
			//{
			//	title: "Tutorial 5: Joint Communication and Sensing for Space: Understanding the Convergence of Space Functions through employing repurposable payloads",
			//	start: "16:30",
			//	end: "18:00",
			//	speaker: "Adam Kapovits (Eurescom), Helmut Zaglauer (Airbus), Jung‐Hyo Kim (Airbus), Marius Corici (FhG‐fokus), Cristian Grozea (FhG‐fokus)",
			//	chair: {
			//		name: "Prof. Markku Juntti",
			//		affiliation: "University of Oulu, Finland"
			//	},
			//	slides: "Tutorial 5.pdf",
			//	vimeo: "928690868",
			//	misc: `<div style="margin-top: 0.5em; font-size: 0.9em; font-style: italic;">
			//		Abstract:  Joint (or Integrated) Communication and Sensing (JC&S) has become a very hot  topic gaining plenty of attention in recent years in particular in the context of the evolution of 5G mobile telecommunications networks towards Beyond 5G (B5G) and 6G. Currently the JC&S applications that are being discussed are almost entirely in the terrestrial domain. However, there are already some significant efforts to address Joint Communication and Sensing using space-based assets or infrastructure as both sensing and communication are part of what is already offered by space-borne infrastructures. Recently for instance the use of MIMO LEO satellite systems with a beam squint-aware precoding scheme to operate communications and target sensing applications simultaneously has been proposed. But there are also many other potential applications where RF sensing from space and 5G Non-terrestrial network (NTN) communications can align to provide new capabilities and services. The emergence of 5G NTN in 3GPP standardization and the expectation that it will be an integral part of 6G necessitates a more thorough investigation of potential commonalities and synergies between JC&S and NTN. The convergence of space functions promises a much more efficient use of space assets with increasing business viability. The tutorial will provide the basic elements to understand the requirements, system concepts and potential benefits of Joint Communication and Sensing from Space.
			//	</div>`
			//},
			{
				title: "Walk to City Hall Oulu (~10min, dress warmly)",
				start: "18:30",
				break: true,
			},
			{
				title: "Welcome Reception",
				start: "19:00",
				end: "20:30",
				break: true,
			}
		]
	},
	{
		title: "Day 2",
		date: "2025-01-29",
		slidefolder: "/slides/2023/AE091EGS",
		items: [
			{
				title: "Registration and Welcome Coffee",
				start: "8:30",
				end: "8:45",
				break: true,
			},
			{
				title: "Conference Opening - Welcome Address",
				speaker: "Markku Juntti (University of Oulu), Gerhard Fettweis (TU Dresden)",
				start: "8:45",
				end: "9:00",
				slides: "Opening_Slides_2025.pdf",
				vimeo: "1062716241",
			},
			{
				title: "Keynote: Network as a Sensor: Evolution Toward 6G and Beyond",
				speaker: "Slawomir Stanczak",
				affiliation: "Heinrich Hertz Institute/TU Berlin",
				start: "9:00",
				end: "9:45",
			//	slides: "Petropulu-slides.pdf",
				vimeo: "1062716405",
			misc: `<div style="margin-top: 0.5em; font-size: 0.9em; font-style: italic;">
					Abstract: The convergence of communication and sensing functionalities in wireless networks is a key feature of next generation communication systems, shaping the evolution towards 6G and beyond. This talk highlights the Integrated Sensing and Communication (ISAC) research within the 6G-RIC research hub. It explores network sensing approaches that build on 5G systems with minimal changes, such as the use of pilot-based Channel State Information (CSI) and channel mapping, as well as more transformative methods that require a redefinition of the radio interface, including novel waveform design and sub-terahertz (sub-THz) ISAC technologies. We will explore both evolutionary and revolutionary approaches for enabling communications networks to function as advanced sensors.
				</div>`
			},
			{
				title: "Keynote: Information theoretic Foundation of ISAC",
				speaker: "Holger Boche",
				affiliation: "TU Munich",
				start: "9:45",
				end: "10:30",
			//	slides: "famaey-slides.pdf",
				vimeo: "1062717068",
			misc: `<div style="margin-top: 0.5em; font-size: 0.9em; font-style: italic;">
					Abstract: As a promising technology in future wireless communication networks, Joint Sensing and Communication (JSAC) has received significant attention in recent years. The characterization of the fundamental limit of the capacity-distortion tradeoff, which is an important metric for evaluating the performance of JSAC systems, is a crucial aspect of JSAC research, in which information theory plays an important role. The talk reviews recent progress in the research of JSAC from an information-theoretic perspective. We start with the monostatic and bistatic JSAC problems, then extend to more complex models, where communication and sensing are no longer limited to conventional message transmission and channel state estimation. In this context, communication and estimation may be measured by different criteria or subject to additional constraints based on specific system requirements..
				</div>`
			},
			{
				title: "30min Coffee Break (incl. Demos)",
				start: "10:30",
				end: "11:00",
				break: true,
			},
			{
				title: "Topical paper session 1: HW and Experiments (5 papers)",
				start: "11:00",
				end: "12:30",
				chair: {
				name: "Padmanava Sen",
				affiliation: "Barkhausen Institut gGmbH, Germany"
				 },
				papers: [
					{
						"title": "Experimental Comparison Between 77 GHz Automotive Radar and 5G Sub-6 GHz Imaging",
						"authors": "Max Basler (Ulm University, Germany)",
					},
					{
						"title": "Bistatic Integrated Sensing and Communication Scenarios with Transmitter and Receiver-Side Trade-Offs",
						"authors": "Gabor Fodor (Ericsson Research & Royal Institute of Technology (KTH), Sweden)",
					},
					{
						"title": "An EBG-Driven 2-Port MIMO Antenna Featuring Enhanced Isolation and Circular Polarization for IEEE 802.11ad JC&S Terminals",
						"authors": "Shahanawaz Kamal (Barkhausen Institut, Germany)"
					},
					{
						"title": "RF Hardware Reconfigurability for Privacy-Preserving Integrated Sensing and Communication",
						"authors": "Padmanava Sen (Research Group Leader, Barkhausen Institut gGmbH, Germany)",
					},
					{
						"title": "Self-Interference Cancellation in Digital Sensing and Communication Arrays",
						"authors": "Jan Adler (Barkhausen Institut, Germany)",
					},
				],
				vimeo: "1062718278",
			},
			{
				title: "Business Lunch",
				start: "12:30",
				end: "13:30",
				break: true,
			},
			{
				title: "Topical paper session 2: PHY and signal processing  (4 papers)",
				start: "13:30",
				end: "14:45",
				chair: {
 				name: "Andre Bourdoux",
 				affiliation: "imec"
				},
				papers: [
					{
						"title": "Multi-View Integrated Imaging and Communication",
						"authors": "Davide Tornielli Bellini (Politecnico di Milano, Italy)",
					},
					{
						"title": "Neural Network-Integrated Multistatic Sensing for Joint Angle Estimation in Cell-Free JCAS Systems",
						"authors": "Fatih Ayten (Tampere University, Finland)"
					},
					{
						"title": "On the Impact of Phase Impairments on Angle Estimation in True-Time-Delay Systems",
						"authors": "Carl R. Collmann (TU Dresden, Germany)",
					},
					{
						"title": "Multi-Bistatic Coordinated Multipoint JCAS: Exploring the Synchronization Requirements",
						"authors": "Abdelrahman Elgamal (RWTH Aachen University, Germany & Chair of Radar Systems Engineering, Germany)",
					},
				],
				vimeo: "1062719730",
			},
			{
				title: "Demo teasers",
				start: "14:45",
				end: "15:00",
				vimeo: "1062720569"
			},
			{
				title: "Demo Show + Coffee Break",
				start: "15:00",
				end: "15:30",
				break: true,
			},

			{
				title: "Topical paper session 3: Waveforms (4 papers)",
				start: "15:30",
				end: "16:45",
				chair: {
 				name: "Arthur Sousa de Sena",
 				affiliation: "UOULU"
				},
				papers: [
					{
						"title": "Frequency Diverse Array OFDM System for Joint Communication and Sensing",
						"authors": "Marcin Wachowiak (KU Leuven, Belgium & IMEC, Belgium)",
					},
					{
						"title": "Evaluating Communication and Sensing Quality in Phase-Modulated FMCW Radar",
						"authors": "Jonas Bönsch (Friedrich-Alexander-Universität Erlangen-Nürnberg)",
					},
					{
						"title": "Joint Optimization of Geometric and Probabilistic Constellation Shaping for OFDM-ISAC Systems",
						"authors": "Benedikt Geiger (Karlsruhe Institute of Technology, Germany)"
					},
					{
						"title": "Sparse Delay Doppler Estimation for Zak-OTFS Enabled ISAC System",
						"authors": "Guddu Kumar (University of Oulu, Finland)",
					},
				],
				vimeo: "1062720889",
			},
			{
				title: "Panel Discussion",
				chair: {
					name: "Gerhard Fettweis and Markku Juntti",
					affiliation: "TU Dresden and University of Oulu"
							},
				speaker: "Gonzalo Seco Granados (Universitat Autonoma of Barcelona), Marja Matinmikko‐Blue (University of Oulu), Petteri Alinikula (Saab), Miguel Bordallo-Lopez (University of Oulu)",
				start: "16:45",
				end: "18:00",
		//slides: [ 
		//{
		//		name: "Intro Richard Stirling-Gallacher", 
		//		file: "panel_stirling-gallacher.pdf"
		//	}, 
		//	{
		//		name: "Intro Marja Matinmikko-Blue", 
		//		file: "panel_matinmikko-blue.pdf"
		//	},
		//	{
		//		name: "Intro Sofie Pollin", 
		//		file: "panel_pollin.pdf"
		//	},
		//	{
		//		name: "Intro Pandharipande", 
		//		file: "panel_pandharipande.pdf"
		//	}
		//],
				vimeo: "1062721994"
			},
			{
				title: "Transfer by bus to Social Dinner leaving in front of the Hotel Lasaretti and in Front of the Radisson Blu (~25min drive)",
				start: "18:15",
				break: true,
			},
			{
				title: "Social Dinner @Maikkulan Kartano",
				misc: `
					<div style="margin-top: 0.5em; font-size: 0.9em; font-style: italic;">
					</div>`,
				start: "19:00",
				break: true,
			},
			
		]
	},
	{
		title: "Day 3",
		date: "2025-01-30",
		slidefolder: "/slides/2023/AE091EGS",
		items: [
			{
				title: "Morning Coffee",
				start: "8:30",
				end: "9:00",
				break: true,
			},
			{
				title: "Keynote: Integration Sensing and Communication for 6G: Waveform Design, Resource Allocation, Application and Prototype Demo",
				speaker: "Zhu Han",
				affiliation: "University of Houston",
				start: "09:00",
				end: "09:45",
		slides: "hand_keynote.pdf",
		vimeo: "1062723515",
		misc: `<div style="margin-top: 0.5em; font-size: 0.9em; font-style: italic;">
					Abstract: Integration of Sensing and Communication (ISAC) is one of the use scenarios in the coming  6G networks. In this talk, we first describe the motivation, history, waveforms and tradeoffs. Then we briefly explain some of our recent works supported by US National Science Foundation including Cross-domain Waveform Design, Multiuser Resource Allocation, RIS-ISAC: DISCO PLS Attack, High Speed Train and Optical ISAC. Finally, we show our prototype demo in IEEE ICCC 2024 and Milcom 2024, and then discuss the standardization and future work.
				</div>`
			},
			{
				title: "Keynote: 6G-Based Joint Communication and Sensing in the Low-Level Airspace",
				speaker: "Markus Klügel",
				affiliation: "Airbus Munich",
				start: "09:45",
				end: "10:30",
		slides: "kluegel_keynote.pdf",
		vimeo: "1062724066",
		misc: `<div style="margin-top: 0.5em; font-size: 0.9em; font-style: italic;">
					Abstract: Radar technology has arguably one of its best known applications in airspace surveillance. However, current aeronautical radar systems are highly-optimized, powerful and expensive systems that cover up to 100km of radius. Their use is therefore mostly limited to the surveillance of commercial flights from few, distinct ground locations to create situational awareness on the higher airspace levels, whereas lower airspace levels strongly rely on position broadcasting and visual recognition by the pilot. The current trend towards automated low-level flight, as used by drones and planned in future by sophisticated air-vehicles such as air-taxis, drives the need for more and more scalable airspace surveillance systems. With the emergence of joint communication and sensing (JC&S) technology in 6G cellular networks, several innovative alternatives are enabled that can enhance situational awareness in the low-level airspace. For example, an entire radar network can be created from a set of base stations, while on-board UEs enhance the view being used as opportunistic air-to-air radar. Also, the cellular network may be configured for on-demand area surveillance or as an input to make better decisions on landing clearance. 
In this talk, an overview over opportunities opened up by 6G in the low-level airspace is provided and aeronautic performance requirements for JC&S are put forward. Furthermore, pro's and con's of different ways to use 6G, reaching from signal choice to level of sensor fusion, as well as related challenges are discussed.
				</div>`
			},
			{
				title: "30min Break (incl. Demos)",
				start: "10:30",
				end: "11:00",
				break: true,
			},
			{
				title: "Topical paper session 4: Applications (6 papers)",
				start: "11:00",
				end: "12:40",
				chair: {
 				name: "Marja Matinmikko-Blue ",
 				affiliation: "University of Oulu"
		},
		papers: [
			{
				"title": "Towards Semantic Radio SLAM with Landmark Feature Extraction in mmWave Networks",
				"authors": "Aki Karttunen (Tampere University, Finland)",
			},
			{
				"title": "Evaluating the Performance of UE-Side Sensing in Multistatic ISAC Systems for Highway Scenarios",
				"authors": "Jan-Steffen Gröneweg (Robert Bosch GmbH & FAU Erlangen, Germany)",
			},
			{
				"title": "FlexiPrint: Adaptive Radio Fingerprinting for Length Variability",
				"authors": "Thayheng Nhem (University of Antwerp - imec, Belgium)",
			},
			{
				"title": "Hybrid Fusion for 802.11ax Wi-Fi-Based Passive Radars Exploiting Beamforming Feedbacks",
				"authors": "Martin Willame (Université Catholique de Louvain (UCLouvain) & Université Libre de Bruxelles (ULB), Belgium)",
			},
			{
				"title": "Leveraging the Digital Twin Channel for Spectrum Anomaly Detection: An Experimental Study",
				"authors": "Anton Schösser (Technische Universität Dresden, Germany)",
			},
			{
				"title": "Spatio-Temporal 3D Point Clouds from WiFi-CSI Data via Transformer Networks",
				"authors": "Tuomas Määttä (University of Oulu, Finland)",
			},
		],
				vimeo: "1062725103",
			},
			{
				title: "Business Lunch",
				start: "12:40",
				end: "13:40",
				break: true,
			},
		
			{
				title: "Topical paper session 5: MIMO (4 papers)",
				start: "13:40",
				end: "15:10",
		chair: {
 		name: "Nhan Ngyuen",
 		affiliation: "University of Oulu"
		},
		papers: [
			{
				"title": "Joint Waveform Design and Sub-Carrier Allocation for Multiuser MIMO ISAC",
				"authors": "Mohammad Hatami (University of Oulu, Finland)",
			},
			{
				"title": "Deep Unfolding-Empowered MmWave Massive MIMO Joint Communications and Sensing",
				"authors": "Nhan Nguyen (University of Oulu, Finland)",
			},
			{
				"title": "Virtual Array for Dual Function MIMO Radar Communication Systems Using OTFS Waveforms",
				"authors": "Kailong Wang (Rutgers University, USA)",
			},
			{
				"title": "Multi-Domain CSI-Based Indoor Localization with Deep Attention Networks for MIMO JCAS System",
				"authors": "Anirban Mukherjee (International Institute of Information Technology Bangalore & University of Oulu, India)",
			},
		//	{
		//		"title": "A Novel Adaptive Spreading Waveform for Integrated Sensing and Communication",
		//		"authors": "Daljeet Singh (University of Oulu, Finland & Lovely Professional University, India); Atul Kumar (Indian Institute of Technology (BHU), Varanasi, India); Hem Joshi (Thapar University, India); Ashutosh Singh (Thapar Institute of Engineering and Technology, India); Mariella Särestöniemi (University of Oulu & Research Unit of Health Sciences and Technology and Center for Wireless Communication, Finland); Teemu Samuli Myllylä (University of Oulu, Finland); Maurizio Magarini (Politecnico di Milano, Italy))",
		//	},
		],
				vimeo: "1062726402",
			},
	//{
	//	title: "15min Break",
	//	start: "14:55",
	//	end: "15:10",
	//	break: true,
	//},
	
			{
				title: "Conference recap and closing",
				speaker: "André Bourdoux (IMEC)",
				start: "15:10",
				end: "15:55",
				slides: [ 
			//	{
			//		name: "Conference Summary", 
			//		file: "summary_bourdoux.pdf"
			//	}, 
				{
					name: "Closing Slides", 
					file: "Closing_Slides_2025.pdf"
				},
				],
		vimeo: "1062728583",
			}

		]
	}
]
