export var legal = `

	<h3>Impressum</h3>
	<h4>Anbieterkennzeichnung</h4>
	<p>
	Barkhausen Institut gGmbH <br>
	Schweriner Straße 1 <br>
	01067 Dresden <br>
	Tel.: +49 351 46339161 <br>
	E-Mail: info(at)barkhauseninstitut.org
	</p>

	<p>
	<strong>Vertretungsberechtigter Geschäftsführer:</strong> Prof. Dr. Gerhard Fettweis
	</p>

	<p>
	<strong>Handelsregister:</strong> Amtsgericht Dresden, HRB 37267
	</p>

	<p>
	Diese Maßnahme wird mitfinanziert mit Steuermitteln auf Grundlage des von den Abgeordneten des Sächsischen Landtags beschlossenen Haushaltes.<br>
	</p>

	<h4>Inhaltlich Verantwortlicher</h4>

	<p>
	Prof. Dr. Gerhard Fettweis
	</p>


	<h4>Technische Betreuung und Webdesign</h4>
	<p>
	Fabian Diehm <br>
	Vodafone Lehrstuhl Mobile Nachrichtensysteme<br>
	Technische Universität Dresden<br>
	<br>
	E-Mail: fabian.diehm(at)ifn.et.tu-dresden.de
	</p>


	<h4>Datenschutzbeauftragte</h4>
	<p>
	Kerstin Herschel, Externe Datenschutzbeauftragte <br>
	PRODATIS CONSULTING AG <br>
	Landhausstraße 8 <br>
	01067 Dresden <br>
	Telefon: +49 351 2662330 <br>
	E-Mail: k.herschel@prodatis.com <br>
	</p>

	<h4>Haftungsausschluss</h4>

	<p>Wir bemühen uns intensiv, auf der Webseite richtige und vollständige Informationen zur Verfügung zu stellen.
	Wir übernehmen jedoch keine Haftung oder Garantie für die Aktualität, Richtigkeit oder Vollständigkeit der auf der Webseite
	bereitgestellten Informationen.
	Dies gilt auch für alle Verweise (Hyperlinks), auf die die Webseite direkt oder indirekt verweist.
	Die Barkhausen Institut gGmbH hat keinen Einfluss auf die aktuelle und zukünftige Gestaltung und auf Inhalte der verlinkten Seiten.
	Wir sind für den Inhalt einer Seite, die mit einem solchen Link erreicht wird, nicht verantwortlich.
	</p>

	<h4>Urheberrecht und Bildnachweis</h4>
	<p>Alle auf dieser Webseite veröffentlichten Inhalte (Layout, Texte, Bilder, Grafiken usw.) unterliegen dem Urheberrecht.
	Jede vom Urheberrechtsgesetz nicht zugelassene Verwertung bedarf vorheriger ausdrücklicher Zustimmung.
	Dies gilt insbesondere für Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw.
	Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen. Wir erlauben ausdrücklich und begrüßen
	das Zitieren unserer Dokumente und Webseite sowie das Setzen von Links auf unsere Website.</p>

	<p>Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten Marken und Warenzeichen
	unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten
	der jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen,
	dass Markenzeichen nicht durch Rechte Dritter geschützt sind.</p>


	<h3>Datenschutz</h3>

	<h4>1. Allgemeine Hinweise</h4>
	<p>Die Sicherheit und die Wahrung der Vertraulichkeit Ihrer personenbezogenen Daten ist uns ein wichtiges Anliegen.
	Im Folgenden finden Sie Informationen, in welchem Rahmen wir Ihre Daten verarbeiten und getroffene Maßnahmen,
	die dem Schutz Ihrer Privatsphäre als Nutzer dieser Seite dienen.
	Alle hier erhobenen Daten werden in unserem Verantwortungsbereich entsprechend den Vorschriften der
	Europäischen Datenschutz-Grundverordnung (DSGVO) und in Übereinstimmung mit den für uns geltenden
	landesspezifischen Datenschutzbestimmungen wie das Bundesdatenschutzgesetz neu (BDSG neu) oder das
	Telemediengesetz (TMG) streng vertraulich behandelt.
	Die Verarbeitung erfolgt entweder gemäß Art. 6 Abs. 1 lit. a DSGVO auf Basis Ihrer Einwilligung, gemäß
	Art. 6 Abs. 1 lit b DSGVO zur Erfüllung oder Anbahnung eines Vertrages mit Ihnen, gemäß Art. 6 Abs. 1 lit. c
	DSGVO zur Erfüllung einer rechtlichen Verpflichtung von uns oder auf der Grundlage gemäß Art. 6 Abs. 1 lit. f DSGVO
	zur Wahrung berechtigter Interessen von uns.
	</p>

	<h4>2. Verantwortliche Stelle</h4>
	<p>
	Barkhausen Institut gGmbH<br>
	Würzburger Str. 46<br>
	01187 Dresden
	</p>
	<p>
	Vertretungsberechtigter Geschäftsführer: Prof. Dr. Gerhard Fettweis<br>
	Tel.: +49 351 46339161<br>
	E-Mail: info(at)barkhauseninstitut.org
	</p>

	<p>Für Fragen, Anregungen oder Kommentare zum Thema Datenschutz wenden Sie sich bitte an unsere Datenschutzbeauftragte (siehe Impressum).</p>


	<h4>3. Erhebung und Verarbeitung persönlicher Informationen</h4>

	<p>Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen.
	Als identifizierbar wird eine natürliche Person gesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung
	wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen identifiziert
	werden kann, oder auch die Ausdrücke der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen
	 Identität dieser natürlichen Person sind.
	</p>

	<p>Die Verarbeitung personenbezogener Daten kann mit oder ohne Hilfe von DV-Anlagen erfolgen und umfasst folgende Tätigkeiten:
	das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung,
	das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung,
	den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.
	</p>

	<p>Wir verarbeiten Ihre persönlichen Informationen, die Sie uns über unsere Webseite mitteilen, nur zur Korrespondenz mit Ihnen
	 und nur für den Zweck, zu dem Sie uns die Daten zur Verfügung gestellt haben.</p>

	 <p>Bei jedem Zugriff auf unsere Webseite erheben und speichern wir automatisch und vorübergehend Informationen,
	 die Ihr Browser während Ihres Nutzens auf unserer Webseite und bei jedem Abruf einer Datei bei uns hinterlässt in einer
	 sogenannten Protokolldatei. Im Einzelnen werden über jeden Zugriff/Abruf folgende Daten gespeichert:</p>

	 <ul>
		 <li>IP-Adresse</li>
		 <li>Datum und Uhrzeit des Abrufs</li>
		 <li>Aufgerufene Seite/Name der abgerufenen Datei</li>
		 <li>Webbrowser und anfragende Domain</li>
		 <li>Übertragene Datenmenge</li>
		 <li>Meldung, ob der Zugriff erfolgreich war</li>
	 </ul>

	 <p>Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der
	 Verbesserung der Stabilität und Funktionalität unserer Webseite. Eine Weitergabe oder anderweitige Verwendung
	 der Daten findet nicht statt. Wir behalten uns allerdings vor, die Server-Logfiles nachträglich zu überprüfen,
	 sollten konkrete Anhaltspunkte auf eine rechtswidrige Nutzung hinweisen.
	 </p>

	 <h4>4. Verwendung und Weitergabe persönlicher Informationen</h4>

	 <p>Ihre persönlichen Daten (z.B. Name, Vorname, Telefon, Anschrift, E-Mail Adresse), die Sie uns über diese Webseite
	 z.B. ein Kontaktformular mitteilen, werden nur zur Korrespondenz mit Ihnen, zur Bearbeitung Ihrer Anfrage oder
	 für den Zweck verarbeitet, zu dem Sie uns die Daten zur Verfügung gestellt haben.
	 </p>

	 <p>Eine sonstige Auswertung der Daten, außer für statistische Zwecke und dann grundsätzlich in anonymisierter Form, erfolgt nicht.</p>

	 <p>Soweit wir zur Durchführung bzw. Erfüllung von Aufgaben für uns Dienstleister in Anspruch nehmen, werden die
	 Vertragsverhältnisse nach den Bestimmungen der Europäischen Datenschutzgrundverordnung (EU-DSGVO) und
	 des Bundesdatenschutzgesetz neu (BDSG neu) schriftlich geregelt. Dies betrifft folgende Kategorien von Empfängern:
	 </p>

	 <ul>
		 <li>IT-Dienstleister zur Gewährleistung der Sicherheit und der Vertraulichkeit</li>
		 <li>Finanzdienstleister zur Abwicklung der Geschäftstätigkeit und Einhaltung gesetzlicher Vorschriften</li>
		 <li>Transportdienstleister</li>
		 <li>Lieferanten zur Abwicklung von Direktversendungen</li>
	  </ul>

	  <p>Zusätzlich können von uns anonyme Daten mit Partnern geteilt werden, um statistische Analysen durchzuführen.
	  Allen diesen Parteien ist es verboten, Ihre persönlichen Angaben zu verwenden, sofern sie nicht für unser
	  Angebot benutzt werden.
	  </p>

	  <p>Unsere Webseite wird auf OVH gehostet. Wir haben mit OVH einen Vertrag zur Auftragsverarbeitung abgeschlossen
	  und setzen die strengen Vorgaben der deutschen Datenschutzbehörden bei der Nutzung von OVH vollständig um.
	  </p>

	  <h4>5. Speicherdauer Ihrer Daten</h4>

	  <p>Ihre personenbezogenen Daten, die uns über unsere Webseite mitgeteilt worden sind, werden nur so lange gespeichert,
	  bis der Zweck erfüllt ist, zu dem sie uns anvertraut wurden und die Speicherung erforderlich ist. Soweit handels-,
	  steuer- oder verwaltungsrechtliche Aufbewahrungsfristen zu beachten sind, kann die Speicherdauer zu bestimmten Daten
	  bis zu 6 oder 10 Jahre betragen.
	  </p>

	 <h4>6. Ihre Datenschutzrechte</h4>

	 <p>Als betroffene Person, deren Daten wir verarbeiten, stehen Ihnen gemäß EU-Datenschutzgrundverordnung umfangreiche Rechte zu.
	 Sie haben das Recht auf Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten insbesondere über die
	 Verarbeitungszwecke, die Kategorien der verarbeiteten personenbezogenen Daten, die Empfänger bzw. Kategorien von Empfängern,
	 gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer bzw. die Kriterien für die
	 Festlegung der Speicherdauer. Sollten Sie mit der Verarbeitung oder Speicherung Ihrer personenbezogenen Daten nicht
	 mehr einverstanden oder Ihre Daten unrichtig sein, haben Sie das Recht auf Berichtigung, Löschung oder
	 Einschränkung der Verarbeitung. Wir werden auf Ihren entsprechenden Antrag bzw. Widerruf hin die notwendigen
	 Korrekturen vornehmen oder die Löschung bzw. Einschränkung der Verarbeitung Ihrer Daten veranlassen
	 oder die notwendigen Korrekturen vornehmen, soweit dies nach dem geltendem Recht möglich ist. Des Weiteren haben
	 Sie bei einer erteilten Einwilligung das Recht auf jederzeitigen Widerspruch der Verarbeitung für die Zukunft,
	 das Recht auf Datenübertragbarkeit sowie das Recht der Beschwerde bei einer Aufsichtsbehörde.
	 </p>

	 <h4>7. Datenverschlüsselung</h4>

	 <p>Zum Schutz der Vertraulichkeit Ihrer Daten verwenden wir bei der Übertragung Ihrer Daten, dem aktuellen Stand der Technik entsprechende
	 Verschlüsselungsverfahren (z.B. SSL) über HTTPS.
	 </p>

	 <h4>8. Cookie-Nutzung</h4>

	 <p>Diese Webseite verwendet keine Cookies</p>

	 <h4>9. Verwendung von Analysediensten und externen Diensten</h4>

	 <p>Diese Webseite benutzt keine Analysedienste oder sonstige externe Dienste.

	 <h4>10. Datenschutzhinweise gemäß EU-Datenschutz-Grundverordnung</h4>

	 <p>Neben diesen webseitenspezifischen Datenschutzhinweisen gelten auch die Datenschutzhinweise gemäß
	 EU-Datenschutz-Grundverordnung:
	 </p>

	 <ul>
		 <li><a href="/fileadmin/user_upload/Allgemein/BI_Informationspflichten_gem._Artikel_13_DSGVO_-_Bewerber_01.pdf">Informationspflichten für Bewerber</a></li>
		 <li><a href="/fileadmin/user_upload/Allgemein/BI_Informationspflichten_gem._13_DSGVO_-_Geschaeftspartner.pdf">Informationspflichten für Geschäftspartner</a></li>
	 </ul>

	 <h4>11. Haftungsausschluss</h4>

	 <p>Wir verweisen gelegentlich auf die Webseiten Dritter. Obwohl wir diese Dritten sorgfältig aussuchen, können wir keine
	 Gewähr und Haftung für die Richtigkeit bzw. Vollständigkeit der Inhalte und die Datensicherheit von Webseiten Dritter übernehmen.
	 Auch gilt diese Datenschutzerklärung nicht für verlinkte Webseiten Dritter. Alle auf dieser Webseite enthaltenen Informationen
	 wurden mit großer Sorgfalt geprüft. Wir übernehmen jedoch keine Gewähr dafür, dass die Inhalte unserer eigenen Webseiten
	 jederzeit korrekt, vollständig und auf dem neuesten Stand sind. Substantielle Änderungen dieser Datenschutzerklärung werden zeitnah
	 online an dieser Stelle aktualisiert.
	 </p>

	 <h4>12. Rechtswirksamkeit dieser Datenschutzerklärung</h4>

	 <p>Diese Datenschutzerklärung ist als Teil des Angebotes unserer Webseite zu betrachten. Sofern Teile oder einzelne Formulierungen dieses
	 Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Textes in
	 ihrem Inhalt und ihrer Gültigkeit davon unberührt.
	 </p>
	`;
