


export var config = {

	utcoffset: "+0100",
	testLocations: ['localhost','test'],
	startTime: Date.now(),
	intervalUpdateTimeLogicS: 10,
	intervalReloadContentsMin: 30,
	limitedCountryCodes: ["CN","IR","KP","CU","ID","SY","SD"],
	faRulerSymbol: 'fa fa-wifi',

	slidefolder: "/slides/2025",

	passwordHash: "2a71a4fb725c9641e44529b6a22e0583",
	passwordCookieExpirationDays: 30,

	currentScheduleItemLinksTo: "#about",

	gotoStartButtonTime: "05.03.2023, 9:00:00",
}
